import React, { useEffect, useState } from 'react'
import Main from 'layouts/main'
import { Col, Row } from 'reactstrap'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import { SIMULACAO } from 'store/modules/simulacao/types'
import { ProdutosSimulacao } from 'models/types'
import CardProduto from './components/CardProdutos'
import DetalhamentoProduto from './components/DetalhamentoProduto'
import ApresentacaoValores from './components/ApresentacaoValores'
import LoadingBasic from 'components/Loading/LoadingBasic'
import useRedux from 'hooks/useRedux'
import * as simulacaoActions from 'store/modules/simulacao/actions'
import ResumoContratacao from './components/Modals/ResumoContratacao'
import IndicacaoComponent from './components/Modals/IndicacaoComponent'
import ModalIN100 from './components/Modals/ModalIN100'

const Simulacao = () => {
  const {
    loadingSimulacao,
    detalhamentoIsOpen,
    produtosDisponiveis,
    produtosSelecionados,
    produtoSelected,
    modalResumoContratacao,
    modalIndicacaoFgts,
    modalIN100,
    beneficios,
    jaSimulado
  } = useSelector<ApplicationState, SIMULACAO>((state) => state.simulacao)

  const { dispatch } = useRedux()
  const [attemptCount, setAttemptCount] = useState(0)
  const maxAttempts = 3

  function selecionaProduto(e: ProdutosSimulacao) {
    let produtosAtuaisSelecionados = [...produtosSelecionados]
    if (produtosAtuaisSelecionados.find((produto) => produto.id === e.id)) {
      produtosAtuaisSelecionados =
        produtosAtuaisSelecionados.filter((produto) => produto.id !== e.id) ||
        []
    } else {
      produtosAtuaisSelecionados.push(e)
    }
    dispatch(
      simulacaoActions.setProdutosSelecionados(produtosAtuaisSelecionados)
    )
  }

  function toggleModal() {
    dispatch(
      simulacaoActions.toggleModalResumoContratacao(!modalResumoContratacao)
    )
  }

  useEffect(() => {
    // Este efeito só é executado se jaSimulado for falso
    if (
      !modalIndicacaoFgts.isOpen &&
      attemptCount < maxAttempts &&
      !jaSimulado
    ) {
      const timer = setTimeout(() => {
        dispatch(simulacaoActions.getPropostasSaga())
        setAttemptCount((prevCount) => prevCount + 1)
      }, 10000)

      return () => clearTimeout(timer)
    }
  }, [dispatch, modalIndicacaoFgts.isOpen, attemptCount, jaSimulado])

  useEffect(() => {
    dispatch(simulacaoActions.getClienteMatriculaCpfSaga())
  }, [dispatch])

  useEffect(() => {
    if (beneficios.length && !jaSimulado) {
      dispatch(simulacaoActions.validaStatusIN100Saga())
    }
  }, [dispatch, beneficios, jaSimulado])

  return (
    <Main>
      <ApresentacaoValores />

      <Row className="d-flex row-gap-20 flex-row-reverse">
        {loadingSimulacao ? (
          <LoadingBasic
            mainText="Estamos realizando a simulação dos produtos disponíveis para você!"
            secondaryText="Aguarde..."
          />
        ) : (
          <>
            {detalhamentoIsOpen && (
              <Col md={5}>
                {produtosDisponiveis.map((produto, i) => {
                  if (
                    produto.servico === produtoSelected.servico &&
                    produto.matricula === produtoSelected.matricula
                  ) {
                    return <DetalhamentoProduto key={i} produto={produto} />
                  }
                })}
              </Col>
            )}

            <Col>
              <Row className="d-flex row-gap-20">
                {produtosDisponiveis?.map((produto, i) => {
                  return (
                    <CardProduto
                      key={i}
                      produto={produto}
                      onCheck={(e) => {
                        selecionaProduto(e)
                      }}
                      checked={
                        !!produtosSelecionados.find(
                          (produtoSelecionado) =>
                            produtoSelecionado.id === produto.id
                        )
                      }
                    />
                  )
                })}
              </Row>
            </Col>
          </>
        )}
      </Row>
      <ResumoContratacao
        isOpen={modalResumoContratacao}
        onToggle={() => toggleModal()}
      />
      <IndicacaoComponent
        indicacao={modalIndicacaoFgts.indicacao}
        isOpen={modalIndicacaoFgts.isOpen}
        metodo={modalIndicacaoFgts.metodoFgts}
        toggle={() => {
          dispatch(simulacaoActions.cleanInfosModalIndicacao())
        }}
      />
      <ModalIN100
        isOpen={modalIN100}
        toggle={() => {
          dispatch(simulacaoActions.toggleModalIN100(false))
        }}
      />
    </Main>
  )
}

export default Simulacao
