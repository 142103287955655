import SimpleCadastro from 'components/SimpleCadastro/SimpleCadastro'
import React, { useEffect } from 'react'
import dadosComplementares from 'assets/images/cadastros/dados-complementares.png'
import { STEPS_CADASTRO_COMPLEMENTAR } from 'views/cadastros/constants/constants'
import Steps from 'components/Steps'
import { Col, Row } from 'reactstrap'
import InputCustom from 'components/Inputs'
import { CustomButton } from 'styles/global'
import * as dadosComplementaresDocumentoActions from 'store/modules/cadastros/dadosComplementaresDocumento/actions'
import { DADOS_COMPLEMENTARES_DOCUMENTO } from 'store/modules/cadastros/dadosComplementaresDocumento/types'
import { ApplicationState } from 'store'
import { useSelector } from 'react-redux'
import useRedux from 'hooks/useRedux'
import CustomSelect from 'components/Select'
import { schema } from './constants/schema'
import * as yup from 'yup'
import { toast } from 'react-toastify'

const DadosComplementaresDocumento = () => {
  const { formulario, invalidFormulario, optionsSelects, loadingSelects } =
    useSelector<ApplicationState, DADOS_COMPLEMENTARES_DOCUMENTO>(
      (state) => state.dadosComplementaresDocumento
    )

  const { dispatch } = useRedux()

  type FormularioField = keyof DADOS_COMPLEMENTARES_DOCUMENTO['formulario']
  const onChange = <T extends FormularioField>(
    value: T extends keyof DADOS_COMPLEMENTARES_DOCUMENTO['formulario']
      ? DADOS_COMPLEMENTARES_DOCUMENTO['formulario'][T]
      : never,
    field: T
  ) => {
    dispatch(
      dadosComplementaresDocumentoActions.setFormularioField(value, field)
    )
  }

  useEffect(() => {
    dispatch(dadosComplementaresDocumentoActions.getOrgaosEmissoresSaga())
    dispatch(dadosComplementaresDocumentoActions.getUfsOrgaosEmissoresSaga())
    dispatch(dadosComplementaresDocumentoActions.getCpfSaga())
  }, [dispatch])

  const onSave = () => {
    dispatch(dadosComplementaresDocumentoActions.cleanInvalidsFormulario())
    schema
      .validate(
        {
          numeroRg: formulario.numeroRg,
          orgaoEmissorId: formulario.orgaoEmissorId,
          ufOrgaoEmissor: formulario.ufOrgaoEmissor,
          dataEmissao: formulario.dataEmissao,
          nomePai: formulario.nomePai,
          nomeMae: formulario.nomeMae
        },
        {
          abortEarly: false
        }
      )
      .then(() => {
        dispatch(dadosComplementaresDocumentoActions.salvarCadastroSaga())
      })
      .catch((err) => {
        err.inner.forEach((e: yup.ValidationError) => {
          dispatch(
            dadosComplementaresDocumentoActions.setInvalidFormularioField(
              {
                invalid: true,
                message: e.message
              },
              e.path as keyof DADOS_COMPLEMENTARES_DOCUMENTO['invalidFormulario']
            )
          )
        })
        toast.warn('Preencha todos os campos corretamente para salvar!')
      })
  }

  return (
    <SimpleCadastro banner={dadosComplementares}>
      <div className="container-fluid d-flex flex-column row-gap-20">
        <Steps steps={STEPS_CADASTRO_COMPLEMENTAR} currentStep={1} />
        <Row>
          <Col>
            <label className="label-12">RG*</label>
            <InputCustom
              type="text"
              placeholder="Digite aqui"
              onChange={(e) => {
                onChange(e.target.value, 'numeroRg')
              }}
              value={formulario.numeroRg}
              invalid={invalidFormulario.numeroRg.invalid}
              errorMessage={invalidFormulario.numeroRg.message}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <label className="label-12">Orgão emissor*</label>
            <CustomSelect
              isLoading={loadingSelects.orgaosEmissor}
              options={optionsSelects.orgaosEmissor}
              placeholder="Selecione"
              onChange={(e) => {
                onChange(e, 'orgaoEmissorId')
              }}
              value={formulario.orgaoEmissorId}
              accessorLabel="nome"
              accessorValue="id"
              invalid={invalidFormulario.orgaoEmissorId.invalid}
              errorMessage={invalidFormulario.orgaoEmissorId.message}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <label className="label-12">UF emissor*</label>
            <CustomSelect
              isLoading={loadingSelects.ufsOrgaoEmissor}
              options={optionsSelects.ufsOrgaoEmissor}
              placeholder="Selecione"
              onChange={(e) => {
                onChange(e, 'ufOrgaoEmissor')
              }}
              value={formulario.ufOrgaoEmissor}
              accessorLabel="nome"
              accessorValue="sigla"
              invalid={invalidFormulario.ufOrgaoEmissor.invalid}
              errorMessage={invalidFormulario.ufOrgaoEmissor.message}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <label className="label-12">Data de expedição*</label>
            <InputCustom
              type="date"
              placeholder="Digite aqui"
              onChange={(e) => {
                onChange(e.target.value, 'dataEmissao')
              }}
              value={formulario.dataEmissao}
              invalid={invalidFormulario.dataEmissao.invalid}
              errorMessage={invalidFormulario.dataEmissao.message}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <label className="label-12">Nome da mãe*</label>
            <InputCustom
              type="text"
              placeholder="Digite aqui"
              onChange={(e) => {
                onChange(e.target.value, 'nomeMae')
              }}
              value={formulario.nomeMae}
              invalid={invalidFormulario.nomeMae.invalid}
              errorMessage={invalidFormulario.nomeMae.message}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <label className="label-12">Nome do pai</label>
            <InputCustom
              type="text"
              placeholder="Digite aqui"
              onChange={(e) => {
                onChange(e.target.value, 'nomePai')
              }}
              value={formulario.nomePai}
              invalid={invalidFormulario.nomePai.invalid}
              errorMessage={invalidFormulario.nomePai.message}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <CustomButton
              background="var(--roxo-riber)"
              width="100%"
              padding="20"
              height="50px"
              borderRadius={21}
              onClick={() => {
                onSave()
              }}
            >
              AVANÇAR
            </CustomButton>
          </Col>
        </Row>
      </div>
    </SimpleCadastro>
  )
}

export default DadosComplementaresDocumento
