import React, { useEffect } from 'react'
import InputCustom from 'components/Inputs'
import CustomSelect from 'components/Select'
import Steps from 'components/Steps'
import useRedux from 'hooks/useRedux'
import { useSelector } from 'react-redux'
import { Col, Row } from 'reactstrap'
import { ApplicationState } from 'store'
import { PROFISSIONAL } from 'store/modules/cadastros/profissional/types'
import { CustomButton } from 'styles/global'
import { STEPS_CADASTRO } from 'views/cadastros/constants/constants'
import { CustomTitleCadastro } from 'views/cadastros/style'
import * as profissionalActions from 'store/modules/cadastros/profissional/actions'
import { maskMoney, maskNumber, removeMaskMoney } from 'util/masks'
import { schemaCadastroServidor } from '../../constants/schema'
import * as yup from 'yup'
import { toast } from 'react-toastify'

const Servidor = () => {
  const {
    cadastroServidor,
    cadastroServidorInvalids,
    optionsSelect,
    loadingOptionsSelect,
    tipoPerfil
  } = useSelector<ApplicationState, PROFISSIONAL>((state) => state.profissional)

  const { dispatch } = useRedux()

  type CadastroServidorFields = keyof PROFISSIONAL['cadastroServidor']
  const onChange = <T extends CadastroServidorFields>(
    value: T extends keyof PROFISSIONAL['cadastroServidor']
      ? PROFISSIONAL['cadastroServidor'][T]
      : never,
    field: T
  ) => {
    dispatch(profissionalActions.setCadastroServidor(value, field))
  }

  useEffect(() => {
    dispatch(profissionalActions.getEstadosSaga())
    dispatch(profissionalActions.getConveniosSaga())
  }, [dispatch])

  useEffect(() => {
    dispatch(profissionalActions.setCadastroServidor('', 'cidadeId'))
    if (cadastroServidor.siglaEstado) {
      dispatch(profissionalActions.getCidadesSaga(cadastroServidor.siglaEstado))
    }
  }, [dispatch, cadastroServidor.siglaEstado])

  useEffect(() => {
    if (tipoPerfil) {
      dispatch(
        profissionalActions.setCadastroServidor(
          tipoPerfil as PROFISSIONAL['cadastroServidor']['servidoresPublicoEnum'],
          'servidoresPublicoEnum'
        )
      )
    }
  }, [tipoPerfil, dispatch])

  function onSave() {
    dispatch(profissionalActions.cleanCadastroServidorInvalids())
    schemaCadastroServidor
      .validate(
        {
          matricula: cadastroServidor.matricula,
          convenioId: cadastroServidor.convenioId,
          salario: Number(removeMaskMoney(cadastroServidor.salario)),
          siglaEstado: cadastroServidor.siglaEstado,
          cidadeId: cadastroServidor.cidadeId,
          margemEmprestimoConsignado: Number(
            removeMaskMoney(cadastroServidor.margemEmprestimoConsignado)
          ),
          margemCartaoConsignado: Number(
            removeMaskMoney(cadastroServidor.margemCartaoConsignado)
          ),
          margemCartaoBeneficio: Number(
            removeMaskMoney(cadastroServidor.margemCartaoBeneficio)
          ),
          servidoresPublicoEnum: cadastroServidor.servidoresPublicoEnum
        },
        { abortEarly: false }
      )
      .then(() => {
        dispatch(profissionalActions.cadastrarServidorSaga())
      })
      .catch((err) => {
        err.inner.forEach((e: yup.ValidationError) => {
          dispatch(
            profissionalActions.setCadastroServidorInvalids(
              {
                invalid: true,
                message: e.message
              },
              e.path as keyof PROFISSIONAL['cadastroServidorInvalids']
            )
          )
        })
        toast.warn('Preencha todos os campos corretamente para salvar!')
      })
  }

  return (
    <div className="container-fluid d-flex flex-column row-gap-20">
      <Steps steps={STEPS_CADASTRO} currentStep={3} />
      <Row className="justify-content-center">
        <Col>
          <CustomTitleCadastro>Campos para preenchimento</CustomTitleCadastro>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col>
          <label className="label-12">Matrícula/Beneficio*</label>
          <InputCustom
            type="text"
            placeholder="Digite aqui"
            onChange={(e) => {
              onChange(maskNumber(e.target.value), 'matricula')
            }}
            value={cadastroServidor.matricula}
            invalid={cadastroServidorInvalids.matricula.invalid}
            errorMessage={cadastroServidorInvalids.matricula.message}
          />
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col>
          <label className="label-12">Convênio*</label>
          <CustomSelect
            placeholder="Selecione"
            options={optionsSelect.convenios}
            onChange={(e) => {
              onChange(e, 'convenioId')
            }}
            value={cadastroServidor.convenioId}
            invalid={cadastroServidorInvalids.convenioId.invalid}
            errorMessage={cadastroServidorInvalids.convenioId.message}
            isLoading={loadingOptionsSelect.convenios}
            accessorLabel="descricao"
            accessorValue="id"
          />
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col>
          <label className="label-12">Salário*</label>
          <InputCustom
            type="text"
            placeholder="Digite aqui"
            onChange={(e) => {
              onChange(maskMoney(e.target.value), 'salario')
            }}
            value={cadastroServidor.salario}
            invalid={cadastroServidorInvalids.salario.invalid}
            errorMessage={cadastroServidorInvalids.salario.message}
          />
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col>
          <label className="label-12">Margem consignável de empréstimo</label>
          <InputCustom
            type="text"
            placeholder="Digite aqui"
            onChange={(e) => {
              onChange(maskMoney(e.target.value), 'margemEmprestimoConsignado')
            }}
            value={cadastroServidor.margemEmprestimoConsignado}
            invalid={
              cadastroServidorInvalids.margemEmprestimoConsignado.invalid
            }
            errorMessage={
              cadastroServidorInvalids.margemEmprestimoConsignado.message
            }
          />
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col>
          <label className="label-12">Margem consignável de cartão</label>
          <InputCustom
            type="text"
            placeholder="Digite aqui"
            onChange={(e) => {
              onChange(maskMoney(e.target.value), 'margemCartaoConsignado')
            }}
            value={cadastroServidor.margemCartaoConsignado}
            invalid={cadastroServidorInvalids.margemCartaoConsignado.invalid}
            errorMessage={
              cadastroServidorInvalids.margemCartaoConsignado.message
            }
          />
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col>
          <label className="label-12">Margem cartão benefício</label>
          <InputCustom
            type="text"
            placeholder="Digite aqui"
            onChange={(e) => {
              onChange(maskMoney(e.target.value), 'margemCartaoBeneficio')
            }}
            value={cadastroServidor.margemCartaoBeneficio}
            invalid={cadastroServidorInvalids.margemCartaoBeneficio.invalid}
            errorMessage={
              cadastroServidorInvalids.margemCartaoBeneficio.message
            }
          />
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col>
          <label className="label-12">Estado*</label>
          <CustomSelect
            placeholder="Selecione"
            options={optionsSelect.estados}
            onChange={(e) => {
              onChange(e, 'siglaEstado')
            }}
            value={cadastroServidor.siglaEstado}
            invalid={cadastroServidorInvalids.siglaEstado.invalid}
            errorMessage={cadastroServidorInvalids.siglaEstado.message}
            isLoading={loadingOptionsSelect.convenios}
            accessorLabel="nome"
            accessorValue="sigla"
          />
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col>
          <label className="label-12">Cidade*</label>
          <CustomSelect
            placeholder="Selecione"
            options={optionsSelect.cidades}
            onChange={(e) => {
              onChange(e, 'cidadeId')
            }}
            value={cadastroServidor.cidadeId}
            invalid={cadastroServidorInvalids.cidadeId.invalid}
            errorMessage={cadastroServidorInvalids.cidadeId.message}
            isLoading={loadingOptionsSelect.convenios}
            accessorLabel="nome"
            accessorValue="id"
          />
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col>
          <CustomButton
            background="var(--roxo-riber)"
            width="100%"
            padding="20"
            height="50px"
            borderRadius={21}
            onClick={() => {
              onSave()
            }}
          >
            AVANÇAR
          </CustomButton>
        </Col>
      </Row>
    </div>
  )
}

export default Servidor
