import React, { useEffect, useState } from 'react'
import { ParcelaFgtsItem } from 'models/types'
import { BiMoney } from 'react-icons/bi'
import { Modal, ModalBody } from 'reactstrap'
import { CustomButton, SimpleTitleCard } from 'styles/global'
import { COLUMNS_DETALHAMENTO_PARCELAS_FGTS } from 'views/simulacao/constants/columns'
import CustomTable from 'components/CustomTable'
import useRedux from 'hooks/useRedux'
import * as simulacaoActions from 'store/modules/simulacao/actions'

interface DetalhamentoParcelasFgtsProps {
  isOpen: boolean
  onToggle: () => void
  parcelasFgts?: ParcelaFgtsItem[]
  valorTotal: number
  parcela: number
}

const DetalhamentoParcelasFgts = ({
  isOpen,
  onToggle,
  parcelasFgts,
  valorTotal,
  parcela
}: DetalhamentoParcelasFgtsProps) => {
  const [valorParcelaState, setValorParcelaState] = useState<number>(valorTotal)
  const [parcelaState, setParcelaState] = useState<number>(parcela)
  const [selecteds, setSelecteds] = useState<string[]>([])

  const { dispatch } = useRedux()

  useEffect(() => {
    if (isOpen) {
      const parcelasFiltradas =
        parcelasFgts?.slice(0, parcelaState).map((parcela) => parcela.id) ?? []
      setSelecteds(parcelasFiltradas)
      setParcelaState(parcelasFiltradas.length)
      setValorParcelaState(
        parcelasFgts
          ?.slice(0, parcelaState)
          ?.map((parcela) => {
            return Number(parcela.parcelaOriginal)
          })
          .reduce((prev, curr) => prev + curr, 0) ?? 0
      )
    }
  }, [isOpen, parcelaState, parcelasFgts])

  function recalculoPropostaFgts() {
    dispatch(
      simulacaoActions.recalculoPropostaFgtsSaga(
        valorParcelaState,
        parcelaState
      )
    )
    onToggle()
  }

  return (
    <Modal size="xl" isOpen={isOpen} toggle={() => onToggle()}>
      <ModalBody>
        <SimpleTitleCard>
          <BiMoney className="icon" />
          Detalhamento parcelas FGTS
        </SimpleTitleCard>
        <CustomTable
          data={parcelasFgts || []}
          columns={COLUMNS_DETALHAMENTO_PARCELAS_FGTS}
          paginationMode="client"
          checkboxSelection={true}
          selectionModel={(e) => {
            if (e?.length >= 5) {
              setSelecteds(e)
              setParcelaState(e.length)
            }
          }}
          disableSelectionOnClick={false}
          selecteds={selecteds}
        />
        <div className="d-flex justify-content-center my-4">
          <CustomButton
            onClick={() => {
              recalculoPropostaFgts()
            }}
            background="var(--roxo-riber)"
          >
            RECALCULAR
          </CustomButton>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default DetalhamentoParcelasFgts
