import { MeusDadosReq } from 'models/meusDados/types'
import { maskDate } from 'util/masks'
import { validaCpf } from 'util/validations'
import * as yup from 'yup'
import { pt } from 'yup-locale-pt'

yup.setLocale(pt)
export const schema: yup.SchemaOf<MeusDadosReq> = yup.object().shape(
  {
    nome: yup.string().required().min(3).max(100).label('Nome'),
    cpf: yup
      .string()
      .test('test-invalid-cpf', 'cpf inválido', (cpf) => validaCpf(cpf || ''))
      .required()
      .label('CPF'),
    dataNascimento: yup
      .string()
      .transform((_, val) => (val ? val : ''))
      .test({
        name: 'dataAtualCheck',
        message: '${label} deve ser anterior a data atual',
        test: function (value) {
          if (value && new Date(value) <= new Date()) {
            return true
          } else {
            return false
          }
        }
      })
      .required()
      .label('Data de nascimento'),
    nomePai: yup
      .string()
      .transform((_, val) => (val ? val : ''))
      .nullable()
      .label('Nome do pai'),
    nomeMae: yup
      .string()
      .transform((_, val) => (val ? val : ''))
      .required()
      .min(3)
      .max(100)
      .label('Nome da mãe'),
    rg: yup
      .string()
      .transform((_, val) => (val ? val : ''))
      .required()
      .max(15)
      .label('RG'),
    orgaoEmissorId: yup
      .string()
      .required()
      .transform((_, val) => (val ? val : ''))
      .label('Orgão emissor'),
    ufOrgaoEmissor: yup
      .string()
      .required()
      .transform((_, val) => (val ? val : ''))
      .label('UF emissor'),
    dataEmissao: yup
      .string()
      .transform((_, val) => (val ? val : ''))
      .test({
        name: 'dataNascimentoCheck',
        message: ({ dataNascimento, label }) =>
          `${label} deve ser posterior a ${maskDate(
            dataNascimento,
            'DD/MM/YYYY'
          )}`,
        params: { dataNascimento: yup.ref('dataNascimento') },
        test: function (value) {
          const { dataNascimento } = this.parent

          if (value && new Date(value) > new Date(dataNascimento)) {
            return true
          } else {
            return false
          }
        }
      })
      .test({
        name: 'dataAtualCheck',
        message: '${label} deve ser anterior a data atual',
        test: function (value) {
          if (value && new Date(value) < new Date()) {
            return true
          } else {
            return false
          }
        }
      })
      .required()
      .label('Data de expedição'),
    ddd: yup
      .string()
      .transform((_, val) => (val ? val : ''))
      .required()
      .min(2)
      .max(3)
      .label('DDD'),
    numero: yup
      .string()
      .transform((_, val) => (val ? val : ''))
      .required()
      .min(8)
      .max(9)
      .label('Número'),
    email: yup
      .string()
      .transform((_, val) => (val ? val : ''))
      .email()
      .required()
      .label('Email'),
    sexo: yup
      .string()
      .required()
      .transform((_, val) => (val ? val : ''))
      .label('Gênero'),
    estadoCivil: yup
      .string()
      .required()
      .transform((_, val) => (val ? val : ''))
      .label('Estado civil'),
    nomeConjuge: yup
      .string()
      .when('estadoCivil', {
        is: (estadoCivil: string) =>
          estadoCivil === 'CASADO' || estadoCivil === 'MARITAL',
        then: yup
          .string()
          .transform((_, val) => (val ? val : ''))
          .min(3)
          .max(100)
          .required()
      })
      .nullable()
      .label('Nome do Cônjuge'),
    cidadeNascimento: yup
      .string()
      .required()
      .transform((_, val) => (val ? val : ''))
      .label('Cidade de nascimento'),
    ufNascimento: yup
      .string()
      .required()
      .transform((_, val) => (val ? val : ''))
      .label('UF nascimento'),
    matriculas: yup.array().of(
      yup.object().shape({
        id: yup.string().nullable().label('Id'),
        numeroMatricula: yup
          .string()
          .max(20)
          .when('tipoPerfil', {
            is: (tipoPerfil: number) =>
              tipoPerfil !== 2 && tipoPerfil !== 0 && tipoPerfil !== 6,
            then: yup
              .string()
              .transform((_, val) => (val ? val : ''))
              .required()
          })
          .nullable()
          .label('Matrícula'),
        tipoPerfil: yup
          .number()
          .typeError('${label} deve ser um número válido')
          .nullable()
          .label('Tipo perfil'),
        descricaoPerfil: yup.string().nullable().label('Descrição perfil'),
        tipoPerfilId: yup.string().required().ensure().label('Tipo perfil'),
        convenioId: yup
          .string()
          .when('tipoPerfil', {
            is: (tipoPerfil: number) => {
              return tipoPerfil === 3 || tipoPerfil === 4 || tipoPerfil === 5
            },
            then: yup
              .string()
              .required()
              .transform((_, val) => (val ? val : ''))
          })
          .nullable()
          .label('Convênio'),
        margemDisponivel: yup
          .number()
          .typeError('${label} deve ser um número válido')
          .when('tipoPerfil', {
            is: (tipoPerfil: number) => {
              return tipoPerfil === 3 || tipoPerfil === 4 || tipoPerfil === 5
            },
            then: yup
              .number()
              .typeError('${label} deve ser um número válido')
              .lessThan(1000000000.0)
          })
          .nullable()
          .transform((_, val) => (val === Number(val) ? val : null))
          .label('Margem consignável de empréstimo'),
        margemCartaoDisponivel: yup
          .number()
          .typeError('${label} deve ser um número válido')
          .when('tipoPerfil', {
            is: (tipoPerfil: number) => {
              return tipoPerfil === 3 || tipoPerfil === 4 || tipoPerfil === 5
            },
            then: yup
              .number()
              .typeError('${label} deve ser um número válido')
              .lessThan(1000000000.0)
          })
          .nullable()
          .transform((_, val) => (val === Number(val) ? val : null))
          .label('Margem consignável de cartão'),
        margemCartaoBeneficio: yup
          .number()
          .typeError('${label} deve ser um número válido')
          .when('tipoPerfil', {
            is: (tipoPerfil: number) => {
              return tipoPerfil === 3 || tipoPerfil === 4 || tipoPerfil === 5
            },
            then: yup
              .number()
              .typeError('${label} deve ser um número válido')
              .lessThan(1000000000.0)
          })
          .nullable()
          .transform((_, val) => (val === Number(val) ? val : null))
          .label('Margem cartão benefício'),
        valorRendaIndividual: yup
          .number()
          .typeError('${label} deve ser um número válido')
          .required()
          .moreThan(0)
          .lessThan(1000000000.0)
          .transform((_, val) => (val === Number(val) ? val : null))
          .label('Salário'),
        contaCorrenteId: yup.string().nullable().label('Conta corrente'),
        tipoContaId: yup
          .string()
          .required()
          .transform((_, val) => (val ? val : ''))
          .ensure()
          .label('Tipo conta'),
        bancoDadosBancariosId: yup
          .string()
          .required()
          .transform((_, val) => (val ? val : ''))
          .ensure()
          .label('Banco'),
        numeroAgencia: yup.string().required().min(1).max(11).label('Agência'),
        digitoVerificadorAgencia: yup
          .string()
          .nullable()
          .max(1)
          .transform((_, val) => (val === Number(val) ? val : null))
          .label('Dígito agência'),
        numeroConta: yup
          .string()
          .required()
          .min(1)
          .max(11)
          .label('Número conta'),
        digitoVerificadorConta: yup
          .string()
          .required()
          .length(1)
          .label('Dígito conta')
      })
    ),
    escolaridadeId: yup
      .string()
      .required()
      .transform((_, val) => (val ? val : ''))
      .label('Escolaridade'),
    cep: yup
      .string()
      .transform((_, val) => (val ? val : ''))
      .required()
      .min(9)
      .max(9)
      .label('CEP'),
    cidade: yup
      .string()
      .required()
      .transform((_, val) => (val ? val : ''))
      .label('Cidade'),
    siglaEstado: yup
      .string()
      .required()
      .transform((_, val) => (val ? val : ''))
      .label('UF'),
    logradouro: yup
      .string()
      .transform((_, val) => (val ? val : ''))
      .required()
      .min(3)
      .max(100)
      .label('Logradouro'),
    numeroEndereco: yup
      .string()
      .required()
      .transform((_, val) => (!isNaN(val) ? val : ''))
      .max(6)
      .label('Número'),
    bairro: yup
      .string()
      .transform((_, val) => (val ? val : ''))
      .required()
      .min(3)
      .max(100)
      .label('Bairro'),
    complemento: yup
      .string()
      .transform((_, val) => (val ? val : ''))
      .nullable()
      .label('Complemento'),
    profissaoId: yup
      .string()
      .required()
      .transform((_, val) => (val ? val : ''))
      .label('Profissão'),
    novaSenha: yup
      .string()
      .when('novaSenha', {
        is: (novaSenha: string) => {
          return novaSenha && novaSenha?.length > 0
        },
        then: yup.string().min(6).required()
      })
      .nullable()
      .label('Senha')
  },
  [['novaSenha', 'novaSenha']]
)
