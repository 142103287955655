import React from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

interface SimpleModalProps {
  isOpen: boolean
  toggle: (e: boolean) => void
  children: React.ReactNode
  header?: React.ReactNode
  footer?: React.ReactNode
  size?: 'sm' | 'md' | 'lg' | 'xl'
  dataBackdrop?: 'static'
}

const SimpleModal = ({
  isOpen,
  toggle,
  children,
  header,
  footer,
  size = 'md',
  dataBackdrop
}: SimpleModalProps) => {
  return (
    <Modal
      size={size}
      isOpen={isOpen}
      toggle={() => dataBackdrop !== 'static' && toggle(!isOpen)}
    >
      {header && (
        <ModalHeader toggle={() => toggle(!isOpen)}>{header}</ModalHeader>
      )}
      <ModalBody>{children}</ModalBody>
      {footer && <ModalFooter>{footer}</ModalFooter>}
    </Modal>
  )
}

export default SimpleModal
