import SimpleTab from 'components/SimpleTab/SimpleTab'
import React, { Fragment, useEffect, useState } from 'react'
import { AiOutlinePlusCircle } from 'react-icons/ai'
import { FiGlobe, FiDownload } from 'react-icons/fi'
import { Col, Row } from 'reactstrap'
import { SimpleTitleCard } from 'styles/global'
import BeneficiosTabs from './BeneficiosTabs'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import { MEUS_DADOS } from 'store/modules/meusDados/types'
import * as meusDadosActions from 'store/modules/meusDados/actions'
import * as simulacaoActions from 'store/modules/simulacao/actions'
import useRedux from 'hooks/useRedux'
import EscolhaPerfil from './EscolhaPerfil'
import { maskNumber, removeMaskMoney } from 'util/masks'
import { toast } from 'react-toastify'
import ModalIN100 from './../../simulacao/components/Modals/ModalIN100'
import { handlerError } from 'util/handlerError'

const Beneficios = () => {
  const {
    meusDados,
    activeTabBeneficio,
    optionsSelects,
    loadingSelects,
    modalIN100
  } = useSelector<ApplicationState, MEUS_DADOS>((state) => state.meusDados)

  const { dispatch } = useRedux()

  const [beneficios, setBeneficios] = useState<
    {
      id: number
      descricao: string
      component: React.ReactNode
    }[]
  >([])

  const [openModalBeneficio, setOpenModalBeneficio] = useState(false)

  useEffect(() => {
    const matriculas: typeof beneficios = []
    meusDados.matriculas?.map((matricula, i) => {
      matriculas.push({
        id: i,
        descricao: String(
          matricula.numeroMatricula ||
            matricula.descricaoPerfil ||
            'Descrição Indisponível'
        ),
        component: (
          <BeneficiosTabs matricula={meusDados.matriculas?.[i]} index={i} />
        )
      })
    })
    setBeneficios(matriculas)
  }, [meusDados.matriculas])

  // useEffect(() => {
  //   dispatch(simulacaoActions.validaStatusIN100Saga())
  // }, [dispatch])

  const getLastMatricula = (): any | undefined => {
    const lastMatricula =
      meusDados.matriculas?.[meusDados.matriculas.length - 1]
    if (lastMatricula) {
      return {
        id: lastMatricula.id,
        numeroMatricula: lastMatricula.numeroMatricula,
        tipoPerfil: lastMatricula.tipoPerfil,
        descricaoPerfil: lastMatricula.descricaoPerfil,
        tipoPerfilId: lastMatricula.tipoPerfilId,
        convenioId: lastMatricula.convenioId,
        margemDisponivel: Number(
          removeMaskMoney(lastMatricula.margemDisponivel)
        ),
        margemCartaoDisponivel: Number(
          removeMaskMoney(lastMatricula.margemCartaoDisponivel)
        ),
        margemCartaoBeneficio: Number(
          removeMaskMoney(lastMatricula.margemCartaoBeneficio)
        ),
        valorRendaIndividual: Number(
          removeMaskMoney(lastMatricula.valorRendaIndividual)
        ),
        contaCorrenteId: lastMatricula.contaCorrenteId,
        tipoContaId: lastMatricula.tipoContaId,
        bancoDadosBancariosId: lastMatricula.bancoDadosBancariosId,
        numeroAgencia: lastMatricula.numeroAgencia,
        digitoVerificadorAgencia: lastMatricula.digitoVerificadorAgencia,
        numeroConta: lastMatricula.numeroConta,
        digitoVerificadorConta: lastMatricula.digitoVerificadorConta
      }
    }
    return undefined
  }

  function onSaveMatricula() {
    const matricula = getLastMatricula()

    try {
      if (matricula.id !== '') {
        toast.warn(
          'Por favor, lembre-se de preencher uma matrícula antes de salvar.'
        )
      } else {
        dispatch(meusDadosActions.salvarMatriculaSaga(matricula))
        dispatch(simulacaoActions.validaStatusIN100Saga())
      }
    } catch (error) {
      handlerError(error)
    }
    dispatch(meusDadosActions.cleanInvalidMeusDados())
  }

  return (
    <>
      <Row>
        <Col>
          <SimpleTitleCard>
            <FiGlobe className="icon" /> Matriculas
          </SimpleTitleCard>
        </Col>
      </Row>
      <SimpleTab
        tabs={beneficios}
        activeTab={activeTabBeneficio}
        actionSetTab={(e) => {
          dispatch(meusDadosActions.setActiveTabBeneficio(e))
        }}
      />
      <Row>
        <Col className="d-flex">
          <div
            onClick={() => {
              setOpenModalBeneficio(true)
            }}
            className="btn-outline-purple my-2"
          >
            <AiOutlinePlusCircle />
            Adicionar Matricula
          </div>
          <Col className="d-flex justify-content-end">
            <div
              onClick={() => {
                onSaveMatricula()
              }}
              className="btn-outline-purple my-2 justify-content-center"
            >
              <FiDownload /> Salvar Matricula
            </div>
          </Col>
        </Col>
      </Row>
      <EscolhaPerfil
        isOpen={openModalBeneficio}
        onToggle={() => {
          setOpenModalBeneficio(!openModalBeneficio)
        }}
        onAdicionar={(e) => {
          dispatch(meusDadosActions.adicionarPerfil(e))
        }}
        perfis={optionsSelects.perfis}
        isLoading={loadingSelects.perfis}
      />
      <ModalIN100
        isOpen={modalIN100}
        toggle={() => {
          dispatch(meusDadosActions.toggleModalIN100(false))
        }}
      />
    </>
  )
}

export default Beneficios
