import useRedux from 'hooks/useRedux'
import React, { useEffect } from 'react'
import { FiUser } from 'react-icons/fi'
import { Col, Row } from 'reactstrap'
import { CustomButton } from 'styles/global'
import { maskMoney, removeMaskMoney } from 'util/masks'
import { ApresentacaoValoresWrapper } from '../style'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import { SIMULACAO } from 'store/modules/simulacao/types'
import * as simulacaoActions from 'store/modules/simulacao/actions'
import { toast } from 'react-toastify'

const ApresentacaoValores = () => {
  const { produtosSelecionados, somatorioTotalProdutos, nomeCompleto } =
    useSelector<ApplicationState, SIMULACAO>((state) => state.simulacao)

  const { dispatch } = useRedux()

  function iniciarContratacao() {
    if (produtosSelecionados.length > 0) {
      if (
        produtosSelecionados.some(
          (produto) =>
            (produto.servico === 5 ||
              produto.servico === 6 ||
              produto.servico === 7 ||
              produto.servico === 8 ||
              produto.servico === 12) &&
            (Number(removeMaskMoney(produto.valorSaque)) >
              produto.valorSaqueMaximo ||
              Number(removeMaskMoney(produto.valorSaque)) <
                produto.valorSaqueMinimo)
        )
      ) {
        simulacaoActions.setInvalidContratacao(true)
        return toast.warn(
          'Insira os valores corretamente para finalizar a contratação'
        )
      } else {
        simulacaoActions.setInvalidContratacao(false)
        return dispatch(simulacaoActions.validacaoContratacaoSaga())
      }
    }
    return toast.warn('Não há produtos selecionados!')
  }

  function onIniciarProdutos() {
    dispatch(simulacaoActions.getPropostasSaga())
  }

  useEffect(() => {
    dispatch(
      simulacaoActions.setSomatorioTotalProdutos(
        produtosSelecionados
          .map((produto) => {
            if (
              produto.servico === 5 ||
              produto.servico === 6 ||
              produto.servico === 7 ||
              produto.servico === 8
            ) {
              if (!produto.flagSaqueAutorizado && !produto.flagSaqueParcelado) {
                return 0
              } else {
                return Number(removeMaskMoney(produto.valorSaque))
              }
            } else {
              return produto.valorTotalFinanciado
            }
          })
          .reduce((prev, curr) => prev + curr, 0)
      )
    )
  }, [produtosSelecionados, dispatch])

  return (
    <ApresentacaoValoresWrapper>
      <Row className="d-flex align-items-center row-gap-15">
        <Col xl={1}>
          <FiUser className="icon" />
        </Col>
        <Col className="d-flex flex-column row-gap-10" xl={5}>
          <label>Olá, {nomeCompleto},</label>
          <span>Estas são as propostas disponíveis para você</span>
        </Col>
        <Col className="d-flex align-items-start" xl={3}>
          <span className="valor-contratacao">
            R$
            {maskMoney(somatorioTotalProdutos?.toFixed(2))}
          </span>
        </Col>
        <Col xl={3} className="mt-3">
          <CustomButton
            onClick={() => onIniciarProdutos()}
            letterSpacing={2}
            width={'100%'}
            className="mb-2"
          >
            SIMULAR
          </CustomButton>
          <CustomButton
            onClick={() => iniciarContratacao()}
            letterSpacing={2}
            width={'100%'}
            disabled={!produtosSelecionados.length}
          >
            CONTRATAR
          </CustomButton>
        </Col>
      </Row>
    </ApresentacaoValoresWrapper>
  )
}

export default ApresentacaoValores
