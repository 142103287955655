import SimpleCadastro from 'components/SimpleCadastro/SimpleCadastro'
import Steps from 'components/Steps'
import React, { useEffect, useState } from 'react'
import { Col, Row } from 'reactstrap'
import { CustomButton, SimpleTitleCard } from 'styles/global'
import { STEPS_CADASTRO_COMPLEMENTAR } from 'views/cadastros/constants/constants'
import dadosComplementares from 'assets/images/cadastros/dados-complementares.png'
import EscolhaPerfil from './components/EscolhaPerfil'
import { AiOutlinePlusCircle } from 'react-icons/ai'
import SimpleTab from 'components/SimpleTab/SimpleTab'
import { FiGlobe } from 'react-icons/fi'
import useRedux from 'hooks/useRedux'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import { stepCadastroComplementarRedirect } from 'util/stepCadastroComplementarRedirect'
import { DADOS_COMPLEMENTARES_DADOS_BANCARIOS } from 'store/modules/cadastros/dadosComplementaresDadosBancarios/types'
import * as dadosComplementaresDadosBancariosActions from 'store/modules/cadastros/dadosComplementaresDadosBancarios/actions'
import BeneficiosTabs from './components/BeneficiosTabs'
import { schema } from './constants/schema'
import { removeMaskMoney } from 'util/masks'
import * as yup from 'yup'
import { toast } from 'react-toastify'
import { IoMdArrowBack } from 'react-icons/io'

const DadosComplementaresDadosBancarios = () => {
  const { formulario, optionsSelects, loadingSelects, activeTabBeneficio } =
    useSelector<ApplicationState, DADOS_COMPLEMENTARES_DADOS_BANCARIOS>(
      (state) => state.dadosComplementaresDadosBancarios
    )

  const { dispatch } = useRedux()

  const [beneficios, setBeneficios] = useState<
    {
      id: number
      descricao: string
      component: React.ReactNode
    }[]
  >([])

  const [openModalBeneficio, setOpenModalBeneficio] = useState(false)

  useEffect(() => {
    const matriculas: typeof beneficios = []
    formulario.dadosBancarios?.map((matricula, i) => {
      matriculas.push({
        id: i,
        descricao: matricula.descricaoPerfil || 'Descrição indisponível',
        component: (
          <BeneficiosTabs
            matricula={formulario.dadosBancarios?.[i]}
            index={i}
          />
        )
      })
    })
    setBeneficios(matriculas)
  }, [formulario.dadosBancarios])

  useEffect(() => {
    dispatch(dadosComplementaresDadosBancariosActions.getPerfisSaga())
    dispatch(dadosComplementaresDadosBancariosActions.getTiposContaSaga())
    dispatch(dadosComplementaresDadosBancariosActions.getBancosSaga())
    dispatch(dadosComplementaresDadosBancariosActions.getConveniosSaga())
    dispatch(dadosComplementaresDadosBancariosActions.getCpfSaga())
  }, [dispatch])

  const onSave = () => {
    dispatch(dadosComplementaresDadosBancariosActions.cleanInvalidsFormulario())
    schema
      .validate(
        {
          dadosBancarios: formulario.dadosBancarios?.map((matricula) => {
            return {
              id: matricula.id,
              numeroMatricula: matricula.numeroMatricula,
              tipoPerfil: matricula.tipoPerfil,
              descricaoPerfil: matricula.descricaoPerfil,
              tipoPerfilId: matricula.tipoPerfilId,
              convenioId: matricula.convenioId,
              margemDisponivel: Number(
                removeMaskMoney(matricula.margemDisponivel)
              ),
              margemCartaoDisponivel: Number(
                removeMaskMoney(matricula.margemCartaoDisponivel)
              ),
              margemCartaoBeneficio: Number(
                removeMaskMoney(matricula.margemCartaoBeneficio)
              ),
              valorRendaIndividual: Number(
                removeMaskMoney(matricula.valorRendaIndividual)
              ),
              contaCorrenteId: matricula.contaCorrenteId,
              tipoContaId: matricula.tipoContaId,
              bancoDadosBancariosId: matricula.bancoDadosBancariosId,
              agencia: matricula.agencia,
              digitoAgencia: matricula.digitoAgencia,
              numeroConta: matricula.numeroConta,
              digitoConta: matricula.digitoConta
            }
          })
        },
        {
          abortEarly: false
        }
      )
      .then(() => {
        if (formulario.dadosBancarios?.length <= 0) {
          toast.warn(
            'Por favor, lembre-se de preencher pelo menos uma matrícula antes de salvar.'
          )
        } else {
          dispatch(
            dadosComplementaresDadosBancariosActions.salvarCadastroSaga()
          )
        }
      })
      .catch((err) => {
        err.inner.forEach((e: yup.ValidationError) => {
          if (e.path?.includes('[')) {
            const field = e.path?.split('[')
            const indexArray = e.path?.match(/\[(.*?)\]/) || []
            const nestedFieldName = e.path?.split('.')
            dispatch(
              dadosComplementaresDadosBancariosActions.setInvalidFormularioFieldArray(
                {
                  invalid: true,
                  message: e.message
                },
                field[0] as keyof DADOS_COMPLEMENTARES_DADOS_BANCARIOS['formulario'],
                Number(indexArray[1]),
                nestedFieldName[1] as never
              )
            )
            dispatch(
              dadosComplementaresDadosBancariosActions.setActiveTabBeneficio(
                Number(indexArray[1])
              )
            )
          }
        })
        toast.warn('Preencha todos os campos corretamente para salvar!')
      })
  }

  return (
    <SimpleCadastro banner={dadosComplementares}>
      <div className="container-fluid d-flex flex-column row-gap-20">
        <CustomButton
          background="var(--roxo-riber)"
          width="15%"
          padding="25"
          onClick={() => {
            stepCadastroComplementarRedirect(3)
          }}
        >
          <IoMdArrowBack style={{ color: 'white', fontSize: '40px' }} />
        </CustomButton>
        <Steps steps={STEPS_CADASTRO_COMPLEMENTAR} currentStep={4} />
        <Row>
          <Col>
            <SimpleTitleCard>
              <FiGlobe className="icon" /> Benefícios
            </SimpleTitleCard>
          </Col>
        </Row>
        <SimpleTab
          tabs={beneficios}
          activeTab={activeTabBeneficio}
          actionSetTab={(e) => {
            dispatch(
              dadosComplementaresDadosBancariosActions.setActiveTabBeneficio(e)
            )
          }}
        />
        <Row>
          <Col className="d-flex">
            <div
              onClick={() => {
                setOpenModalBeneficio(true)
              }}
              className="btn-outline-purple my-2"
            >
              <AiOutlinePlusCircle />
              Adicionar benefício
            </div>
          </Col>
        </Row>
        <Row>
          <CustomButton
            background="var(--roxo-riber)"
            width="100%"
            padding="20"
            height="50px"
            borderRadius={21}
            onClick={() => {
              onSave()
            }}
          >
            AVANÇAR
          </CustomButton>
        </Row>
        <EscolhaPerfil
          isOpen={openModalBeneficio}
          onToggle={() => {
            setOpenModalBeneficio(!openModalBeneficio)
          }}
          onAdicionar={(e) => {
            dispatch(
              dadosComplementaresDadosBancariosActions.adicionarPerfil(e)
            )
          }}
          perfis={optionsSelects.perfis}
          isLoading={loadingSelects.perfis}
        />
      </div>
    </SimpleCadastro>
  )
}

export default DadosComplementaresDadosBancarios
