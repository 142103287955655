/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import {
  DataGrid,
  GridColDef,
  GridFeatureMode,
  GridRowHeightParams,
  GridRowHeightReturnValue,
  ptBR
} from '@mui/x-data-grid'
import { CustomTableWrapper } from './style'
import NoData from './NoData'
import NoResultsFound from './NoResultsFound'

interface CustomTableProps {
  data?: any[]
  columns: GridColDef[]
  isLoading?: boolean
  onPageChange?: any
  onPageSizeChange?: any
  page?: number
  pageSize?: number
  setPage?: (e: any) => void
  setPageSize?: (e: any) => void
  paginationMode?: GridFeatureMode
  rowCount?: number
  checkboxSelection?: boolean
  getRowHeight?: (params: GridRowHeightParams) => GridRowHeightReturnValue
  selectionModel?: (e: any) => void
  autoHeight?: boolean
  selecteds?: string[]
  disableSelectionOnClick?: boolean
}

export default function CustomTable({
  data,
  columns,
  isLoading,
  onPageChange,
  onPageSizeChange,
  page,
  pageSize,
  setPage,
  setPageSize,
  paginationMode = 'server',
  rowCount,
  checkboxSelection,
  getRowHeight,
  selectionModel,
  autoHeight,
  selecteds,
  disableSelectionOnClick = false
}: CustomTableProps) {
  const [rowCountState, setRowCountState] = useState(rowCount)
  const [dataState, setDataState] = useState<any[]>()

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      rowCount !== undefined ? rowCount : prevRowCountState
    )
  }, [rowCount])

  useEffect(() => {
    setTimeout(() => {
      data && setDataState(data)
    }, 500)
  }, [data])

  return (
    <CustomTableWrapper autoHeight={autoHeight}>
      <DataGrid
        rows={dataState || []}
        columns={columns}
        rowsPerPageOptions={[10, 25, 100]}
        page={page}
        pageSize={pageSize}
        paginationMode={paginationMode}
        onPageChange={(newPage) => {
          if (setPage) setPage(newPage)
          if (onPageChange) onPageChange(newPage, pageSize)
        }}
        onPageSizeChange={(newPageSize) => {
          if (setPageSize) setPageSize(newPageSize)
          if (onPageSizeChange) onPageSizeChange(page, newPageSize)
        }}
        loading={isLoading}
        rowCount={rowCountState || data?.length}
        disableSelectionOnClick={disableSelectionOnClick}
        checkboxSelection={checkboxSelection}
        components={{
          NoRowsOverlay: () => {
            return <NoData />
          },
          NoResultsOverlay: () => {
            return <NoResultsFound />
          }
        }}
        sx={{
          '.MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel':
            {
              marginTop: '1em',
              marginBottom: '1em'
            }
        }}
        disableVirtualization
        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
        getRowHeight={(params) => getRowHeight?.(params)}
        onSelectionModelChange={selectionModel}
        selectionModel={selecteds}
        autoHeight={autoHeight}
      />
    </CustomTableWrapper>
  )
}
