import React, { useEffect } from 'react'
import InputCustom from 'components/Inputs'
import CustomSelect from 'components/Select'
import Steps from 'components/Steps'
import { useSelector } from 'react-redux'
import { Col, Row } from 'reactstrap'
import { ApplicationState } from 'store'
import { PROFISSIONAL } from 'store/modules/cadastros/profissional/types'
import { CustomButton } from 'styles/global'
import { STEPS_CADASTRO_APOSENTADO } from 'views/cadastros/constants/constants'
import { CustomTitleCadastro } from 'views/cadastros/style'
import * as profissionalActions from 'store/modules/cadastros/profissional/actions'
import useRedux from 'hooks/useRedux'
import { maskMoney, maskNumber, removeMaskMoney } from 'util/masks'
import { schemaCadastroAposentado } from '../../constants/schema'
import * as yup from 'yup'
import { toast } from 'react-toastify'

const Aposentado = () => {
  const {
    cadastroAposentado,
    optionsSelect,
    cadastroAposentadoInvalids,
    loadingOptionsSelect
  } = useSelector<ApplicationState, PROFISSIONAL>((state) => state.profissional)

  const { dispatch } = useRedux()

  type CadastroAposentadoFields = keyof PROFISSIONAL['cadastroAposentado']
  const onChange = <T extends CadastroAposentadoFields>(
    value: T extends keyof PROFISSIONAL['cadastroAposentado']
      ? PROFISSIONAL['cadastroAposentado'][T]
      : never,
    field: T
  ) => {
    dispatch(profissionalActions.setCadastroAposentado(value, field))
  }

  useEffect(() => {
    dispatch(profissionalActions.getEstadosSaga())
  }, [dispatch])

  useEffect(() => {
    dispatch(profissionalActions.setCadastroAposentado('', 'cidadeId'))
    if (cadastroAposentado.siglaEstado) {
      dispatch(
        profissionalActions.getCidadesSaga(cadastroAposentado.siglaEstado)
      )
    }
  }, [dispatch, cadastroAposentado.siglaEstado])

  function onSave() {
    dispatch(profissionalActions.cleanCadastroAposentadoInvalids())
    schemaCadastroAposentado
      .validate(
        {
          matricula: cadastroAposentado.matricula,
          salario: Number(removeMaskMoney(cadastroAposentado.salario)),
          siglaEstado: cadastroAposentado.siglaEstado,
          cidadeId: cadastroAposentado.cidadeId
        },
        { abortEarly: false }
      )
      .then(() => {
        dispatch(profissionalActions.cadastrarAposentadoSaga())
      })
      .catch((err) => {
        err.inner.forEach((e: yup.ValidationError) => {
          dispatch(
            profissionalActions.setCadastroAposentadoInvalid(
              {
                invalid: true,
                message: e.message
              },
              e.path as keyof PROFISSIONAL['cadastroAposentadoInvalids']
            )
          )
        })
        toast.warn('Preencha todos os campos corretamente para salvar!')
      })
  }

  return (
    <div className="container-fluid d-flex flex-column row-gap-20">
      <Steps steps={STEPS_CADASTRO_APOSENTADO} currentStep={3} />
      <Row className="justify-content-center">
        <Col>
          <CustomTitleCadastro>Campos para preenchimento</CustomTitleCadastro>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md={6}>
          <label className="label-12">Matrícula/Beneficio*</label>
          <InputCustom
            type="text"
            placeholder="Digite aqui"
            onChange={(e) => {
              onChange(maskNumber(e.target.value), 'matricula')
            }}
            value={cadastroAposentado.matricula}
            invalid={cadastroAposentadoInvalids.matricula.invalid}
            errorMessage={cadastroAposentadoInvalids.matricula.message}
          />
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md={6}>
          <label className="label-12">Salário*</label>
          <InputCustom
            type="text"
            placeholder="Digite aqui"
            onChange={(e) => {
              onChange(maskMoney(e.target.value), 'salario')
            }}
            value={cadastroAposentado.salario}
            invalid={cadastroAposentadoInvalids.salario.invalid}
            errorMessage={cadastroAposentadoInvalids.salario.message}
          />
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md={6}>
          <label className="label-12">Estado*</label>
          <CustomSelect
            placeholder="Selecione"
            options={optionsSelect.estados}
            onChange={(e) => {
              onChange(e, 'siglaEstado')
            }}
            value={cadastroAposentado.siglaEstado}
            invalid={cadastroAposentadoInvalids.siglaEstado.invalid}
            errorMessage={cadastroAposentadoInvalids.siglaEstado.message}
            isLoading={loadingOptionsSelect.estados}
            accessorLabel="nome"
            accessorValue="sigla"
          />
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md={6}>
          <label className="label-12">Cidade*</label>
          <CustomSelect
            placeholder="Selecione"
            options={optionsSelect.cidades}
            onChange={(e) => {
              onChange(e, 'cidadeId')
            }}
            value={cadastroAposentado.cidadeId}
            invalid={cadastroAposentadoInvalids.cidadeId.invalid}
            errorMessage={cadastroAposentadoInvalids.cidadeId.message}
            isLoading={loadingOptionsSelect.cidades}
            accessorLabel="nome"
            accessorValue="id"
          />
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md={6}>
          <CustomButton
            background="var(--roxo-riber)"
            width="100%"
            padding="20"
            height="50px"
            borderRadius={21}
            onClick={() => {
              onSave()
            }}
          >
            AVANÇAR
          </CustomButton>
        </Col>
      </Row>
    </div>
  )
}

export default Aposentado
