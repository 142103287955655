import { TYPES } from './types'
import { SagaIterator } from 'redux-saga'
import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects'
import * as loadingActions from 'store/modules/loading/actions'
import * as loginActions from 'store/modules/login/actions'
import * as cadastroInicialController from 'controller/cadastroInicialController'
import * as cadastroInicialActions from 'store/modules/cadastros/cadastroInicial/actions'
import { CADASTRO_INICIAL } from './types'
import { ApplicationState } from 'store'
import { handlerError } from 'util/handlerError'
import { toast } from 'react-toastify'
import { removeMaskCPF, removeMaskPhone } from 'util/masks'

function* salvarCadastroInicial(): SagaIterator {
  const { formulario, modalSenha }: CADASTRO_INICIAL = yield select(
    (state: ApplicationState) => state.cadastroInicial
  )
  try {
    yield put(loadingActions.setLoading(true))
    yield call(cadastroInicialController.salvarCadastroInicial, {
      cpf: removeMaskCPF(formulario.cpf),
      nome: formulario.nome,
      dataNascimento: formulario.dataNascimento,
      ddd: removeMaskPhone(formulario.celular?.substring(1, 3)),
      numero: removeMaskPhone(formulario.celular?.substring(5)),
      email: formulario.email,
      senha: modalSenha.senha,
      politicaCompartilhamento: formulario.politicaCompartilhamento
    })
    yield put(loginActions.setLogin(formulario.email, 'email'))
    yield put(loginActions.setLogin(modalSenha.senha, 'senha'))
    yield put(cadastroInicialActions.cleanFormulario())
    yield put(cadastroInicialActions.cleanModalSenha())
    yield put(cadastroInicialActions.setShowModalSenha(false))
    toast.success('Informações salvas com sucesso!')
    yield put(loginActions.login())
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* getAvisoPrivacidade(): SagaIterator {
  try {
    yield put(cadastroInicialActions.setLoadingAvisoPrivacidade(true))
    const response = yield call(cadastroInicialController.getAvisoPrivacidade)
    yield put(
      cadastroInicialActions.setAvisoPrivacidadeBase64(response?.data?.content)
    )
  } catch (error) {
    handlerError(error, 'Não foi possível obter o aviso de privacidade')
  } finally {
    yield put(cadastroInicialActions.setLoadingAvisoPrivacidade(false))
  }
}

export function* watchSalvarCadastroInicial() {
  yield takeEvery(TYPES.SALVAR_CADASTRO_INICIAL_SAGA, salvarCadastroInicial)
}

export function* watchGetAvisoPrivacidade() {
  yield takeEvery(TYPES.GET_AVISO_PRIVACIDADE_SAGA, getAvisoPrivacidade)
}

function* cadastroInicial() {
  yield all([fork(watchSalvarCadastroInicial), fork(watchGetAvisoPrivacidade)])
}

export default cadastroInicial
