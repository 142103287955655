// const config = {
//   AMBIENTE: 'DESENV',
//   API_SIMULADOR: 'https://localhost:5021/api/'
// }

/** Ambiente de testes */
// const config = {
//   AMBIENTE: 'QA',
//   API_SIMULADOR: 'https://ec2-18-213-234-127.compute-1.amazonaws.com:5701/api/'
// }

/** Ambiente de homologação */
// const config = {
//   AMBIENTE: 'HML',
//   API_SIMULADOR: 'https://apihml.ribercred.com.br/api/'
// }

/** Ambiente de produção */
const config = {
  AMBIENTE: 'PROD',
  API_SIMULADOR: 'https://api.ribercred.com.br/api/'
}

export default config
